import React from "react"
import { useFormContext } from "react-hook-form"
import InputBox from "./InputBox"

const TextAreaInput = (props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  const { name, placeholder, label, boxClassName, value, disable } = props
  return (
    <InputBox
      name={name}
      label={label}
      errors={errors}
      className={boxClassName}
      
    >
      <textarea
        id={name}
        className={`input-field input-textarea ${boxClassName}`}
        placeholder={placeholder}
        defaultValue={value}
        {...props}
        {...register(name)}
        // disabled={disable}
      />
    </InputBox>
  )
}

export default TextAreaInput
